<template>
    <el-dialog title="查看" :visible="isShowType" @close='close' width='650px'
    :destroy-on-close="true"
    :close-on-click-modal='false'>
      <div class="state">
        <span v-if="formData.recordStatus === '0'"><i class="el-icon-success" :style="{ color: getColor(formData.recordStatus)}"></i> &nbsp; 预约成功</span>
        <span v-if="formData.recordStatus === '1'"><i class="el-icon-error" :style="{ color: getColor(formData.recordStatus)}"></i> &nbsp; 预约失败</span>
        <span v-if="formData.recordStatus === '2'"><i class="el-icon-error"></i> &nbsp; 预约取消</span>
      </div>
      <el-form class="" label-width="125px" label-position="right">
        <el-form-item label="预约项目：">
          {{ formData.itemName }}
        </el-form-item>
        <el-form-item label="所属公园：">
          {{ formData.parkName }}
        </el-form-item>
        <el-form-item label="预约人姓名：">
          {{ formData.appUser ? formData.appUser.name : '' }}
        </el-form-item>
        <el-form-item label="预约人手机号：">
          {{ formData.appUser ? formData.appUser.mobile : '' }}
        </el-form-item>
        <el-form-item label="预约日期：">
          {{ formData.enterDate }}
        </el-form-item>
        <el-form-item label="预约入园时间段：">
          {{ formData.time }}
        </el-form-item>
        <el-form-item label="预约人数：">
          {{ formData.enterNum }}
        </el-form-item>

        <div class="title">入园码</div>
        <div class="code" :style="{ background: isNormalCode ? getColor('0') : getColor('1')}">
          <div v-for="(item, index) in formData.appointItemRecordRelList" :key="index">
            <div>{{item.touristName}}</div>
            <div>
              <span>{{item.identityCard}}</span>
              <span>{{item.touristPhone}}</span>
              <img :src="item.enterQrCode || ''" alt="" width="104" height="104">
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
</template>

<script>
export default {
  typeName: 'AppointTouristDialogDetail',
  props: {
    isShowType: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      formData: {}
    }
  },
  created () {
    this.getList()
  },
  watch: {
  },
  computed: {
    isNormalCode () {
      let state = true
      if (this.formData.appointItemRecordRelList) {
        this.formData.appointItemRecordRelList.forEach(item => {
          if (item.skmStatus === '1') {
            state = false
          }
        })
        return state
      }
      return state
    }
  },
  methods: {
    getColor (value) {
      switch (value) {
        case '0': return '#00B7B0'
        case '1': return '#F01919'
        case '2': return '#606266'
        default: return '--'
      }
    },
    getList () {
      this.loading = true
      this.$axios.get(this.$apis.appointManage.selectAppointItemRecordByRecordId, {
        recordId: this.id
      }).then(res => {
        this.formData = res || {}
      }).finally(() => {
        this.loading = false
      })
    },
    close () {
      this.$emit('update:isShowType', false)
      this.formData = {}
    }
  }
}
</script>
<style scoped lang="scss">
.state{
  height: 28px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 17px;
  i{
    width: 14px;
    height: 14px;
  }
}
.title{
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
  margin: 20px 0 16px 0;
}
.code{
  display: flex;
  flex-wrap: wrap;
  width: 580px;
  min-height: 188px;
  background: #20E2B1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  padding: 16px 12px 0px 12px;
    >div{
      display: flex;
      width: 50%;
      margin-bottom: 16px;
      img{
        margin-top: 10px;
      }
      >div{
        &:nth-of-type(1){
          width: 85px;
          text-align: right;
          white-space: nowrap;
          word-break: keep-all;
          text-overflow: ellipsis;
          margin-right: 10px;
          overflow: hidden;
        }
        &:nth-of-type(2){
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: 20px;
          margin-bottom: 2px;
        }
      }
    }
  }
</style>
