
import { Component, Vue } from 'vue-property-decorator'
import TouristDialogDetail from './TouristDialogDetail.vue'
import { KeepAlive } from '@/utils/decorators'
@Component({
  components: { TouristDialogDetail },
  filters: {
    filterState (value: string) {
      switch (value) {
        case '0': return '预约成功'
        case '1': return '预约失败'
        case '2': return '预约取消'
        default: return '--'
      }
    }
  }
})
@KeepAlive
export default class AppointTourist extends Vue {
  private loading = false
  private dateRange = [this.defaultStartTime, this.defaultEndTime]
  private info = {
    parkId: this.defaultParkId,
    recordStatus: '',
    startTime: this.defaultStartTime,
    endTime: this.defaultEndTime,
    itemId: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData = []
  private isShowType = false
  private id = ''
  private appointList = []
  private count = 0

  get routeQuery () {
    return this.$route.query || {}
  }

  get defaultParkId () {
    return this.routeQuery.parkId || ''
  }

  get defaultStartTime () {
    return this.routeQuery.startTime || ''
  }

  get defaultEndTime () {
    return this.routeQuery.endTime || ''
  }

  get projectList () {
    return this.$store.state.projectList || []
  }

  created (): void {
    this.loadData()
    this.selectEnterNumTotal()
  }

  selectAppoint () {
    if (this.info.parkId) {
      this.getAppointItem()
    } else {
      this.info.itemId = ''
      this.appointList = []
    }
  }

  // 获取项目
  getAppointItem () {
    this.$axios.get(this.$apis.appointManage.selectAppointItemList, {
      parkId: this.info.parkId
    }).then(res => {
      this.appointList = res || []
    })
  }

  // 查询对象总和
  selectEnterNumTotal () {
    this.$axios.get(this.$apis.appointManage.selectEnterNumTotal, this.info).then(res => {
      this.count = res || 0
    })
  }

  loadData () {
    this.loading = true
    if (this.dateRange) {
      this.info.startTime = this.dateRange[0] || ''
      this.info.endTime = this.dateRange[1] || ''
    } else {
      this.info.startTime = ''
      this.info.endTime = ''
    }
    this.$axios.get(this.$apis.appointManage.selectAppointItemRecordByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
    this.selectEnterNumTotal()
  }

  onDetail (recordId: string) {
    this.isShowType = true
    this.id = recordId
  }
}
